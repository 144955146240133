















































































































import Vue from 'vue';
import 'vue-select/dist/vue-select.css';
import { recursiveFillData } from '@/helper/functions';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import watchers from '@/watchers/change-data-for-price-request/individual-insurance/basic-tab';
import { MACHINE_FAHRBAR, MACHINE_KRAFTWERKE, MACHINE_STATIONAR, machineTypes } from '@/constant/constants';
import ZuersZone from '@/components/form/zuers-zone.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default Vue.extend({
  name: 'individual-insurance-basic',
  mixins: [dataChangeMixin],
  components: {
    ZuersZone,
  },
  data() {
    return {
      name: 'individual-insurance-basic',
      key: 'INDIVIDUAL_INSURANCE',
      vertragsart: '',
      anschriftNeubau: {
        Auswahl: '',
        Strabe: '',
        Nr: '',
        PLZ: '',
        Ort: '',
        Land: '',
        Zurs: '',
        Starkregenzone: '',
        Naturgefahrenklasse2: '',
        Naturgefahrenklasse3: '',
      },
      machineTypes,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'insuranceFormsApiData'],
  watch: {
    ...watchers,
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
    vuexZurs(newValue: string) {
      this.anschriftNeubau.Zurs = newValue;
      this.anschriftNeubau.Starkregenzone = newValue;
    },
  },
  methods: {
    ...mapMutations('zuersZoneModule', {
      updatePlace: 'SET_PLACE',
      updatePostalCode: 'SET_POSTAL_CODE',
      updateStreet: 'SET_STREET',
      updateHouseNumber: 'SET_HOUSE_NUMBER',
      updateZurs: 'SET_ZURS',
      resetAddres: 'RESET_STATE',
    }),
    setZuersAddressToStore() {
      this.resetAddres();
      this.updatePlace(this.anschriftNeubau.Ort);
      this.updatePostalCode(this.anschriftNeubau.PLZ);
      this.updateStreet(this.anschriftNeubau.Strabe);
      this.updateHouseNumber(this.anschriftNeubau.Nr);
      this.updateZurs(this.anschriftNeubau.Zurs);
    },
    handleVertragsartChange(newValue: string) {
      if (newValue === MACHINE_FAHRBAR || newValue === MACHINE_STATIONAR) {
        this.key = 'INDIVIDUAL_INSURANCE';
        this.$emit('on-key-change', this.key);
      } else if (newValue === MACHINE_KRAFTWERKE) {
        this.key = 'MACHINES_POWER_PLANTS_INSURANCE';
        this.$emit('on-key-change', this.key);
      }
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.basicData) {
        this.vertragsart = this.insuranceFormsApiData.basicData.vertragsart;
        recursiveFillData(this.anschriftNeubau, this.insuranceFormsApiData.basicData.anschriftNeubau);
      }
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData.basicData;
      this.vertragsart = calculationData.vertragsart;
      this.anschriftNeubau = calculationData.anschriftNeubau;
    },
    onSubmit(goNextPage = true) {
      const data = {
        vertragsart: this.vertragsart,
        anschriftNeubau: this.anschriftNeubau,
      };
      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
    savePdf() {
      // save insurance, get id and make pdf
      this.$emit('add-to-queue', 'saveBasicPDF', '');
      this.onSubmit(false);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    this.setZuersAddressToStore();
    (this as any).startWatcherActivated();
  },
  computed: {
    titleTab(): string {
      return 'Versicherungsort';
    },
    vuexZurs() {
      return this.$store.getters['zuersZoneModule/getZurs'];
    },

  },
});
