import { WatchOptions } from 'vue';

export default {
  vertragsart(newVal: string, oldVal: string) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
} as Record<string, (newVal: any, oldVal: any, options?: WatchOptions) => void>;
